<template>
  <v-row>
    <v-col cols="12">
      <v-switch :label="$t('active')" v-model="obCurrency.active"></v-switch>
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obCurrency.name" />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obCurrency.code" label="code" />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obCurrency.symbol" label="symbol" />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text
        v-model="obCurrency.external_id"
        label="bcu.currency.id"
      />
    </v-col>

    <v-col cols="12">
      <v-textarea
        :label="$t('description')"
        outlined
        v-model="obCurrency.preview_text"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { Currency } from "@planetadeleste/vue-mc-shopaholic";

@Component
export default class CurrenciesSettings extends Vue {
  @VModel({ type: Object, default: () => new Currency() })
  obCurrency!: Currency;
}
</script>
