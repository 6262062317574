<template>
  <sheet-drawer v-model="drawer" @close="cancel">
    <v-form class="fill-height" @submit.prevent v-if="obModel">
      <gw-form-observer @save="save" @cancel="cancel" :loading="isLoading">
        <v-tabs right v-model="tab">
          <v-tab v-for="(item, index) in tabItems" :key="`tab-item-${index}`">
            {{ $t(item) }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="(item, index) in tabItems"
            :key="`tab-content-${index}`"
          >
            <component :is="item" v-model="obModel" />
          </v-tab-item>
        </v-tabs-items>
      </gw-form-observer>
    </v-form>
  </sheet-drawer>
</template>

<script lang="ts">
import { Mixins, Component, PropSync } from "vue-property-decorator";
import CurrenciesMixin from "@/modules/currencies/mixins/CurrenciesMixin";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import Settings from "@/modules/currencies/components/tabs/Settings.vue";

@Component({
  components: {
    SheetDrawer,
    Settings,
  },
})
export default class CurrenciesForm extends Mixins(CurrenciesMixin) {
  @PropSync("open", { type: Boolean, default: false }) drawer!: boolean;
  tab = 0;
  tabItems: string[] = ["settings"];
}
</script>
